import { useEffect, useRef } from "react"
import * as THREE from 'three'
import { Group } from "three";
import { OrbitControls, MapControls } from 'three/examples/jsm/controls/OrbitControls'
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

const fov = 40;
const aspect = 2;  // the canvas default
const near = 0.1;
const far = 1000;
const camera = new THREE.PerspectiveCamera(fov, aspect, near, far)
const scene = new THREE.Scene()
const renderer = new THREE.WebGLRenderer()
const orbitControls = new OrbitControls(camera, renderer.domElement)
// const mapControls = new MapControls(camera, renderer.domElement)

export default function ThreeJS(props: any) {
  
  const ref = useRef<HTMLDivElement>(null)

  const resize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight)
  }

  useEffect(() => {

    camera.position.z = 120
    scene.background = new THREE.Color(0xaaaaaa)

    // light
    let lightColor = 0xffffff
    let lightIntensity = 1
    let light = new THREE.DirectionalLight(lightColor, lightIntensity)
    light.position.set(0, 20, 20)
    scene.add(light)

    // cylinder
    const radiusTop = 20;  // ui: radiusTop
    const radiusBottom = 20;  // ui: radiusBottom
    const height = 8;  // ui: height
    const radialSegments = 40;  // ui: radialSegments
    const cylinderGeom = new THREE.CylinderGeometry(radiusTop, radiusBottom, height, radialSegments);
    const cylinderMaterial = new THREE.MeshPhongMaterial({ color: 0xcccccc, shininess: 150 })
    const cylinder = new THREE.Mesh(cylinderGeom, cylinderMaterial)
    scene.add(cylinder)

    // model 1
    const gltfLoader = new GLTFLoader();
    let obj1: Group
    gltfLoader.load('1 узел .glb', (gltf) => {
      obj1 = gltf.scene;
      cylinder.add(obj1);
      obj1.position.setY(4)
      obj1.position.setX(8)
      obj1.position.setZ(8)
    })
    let obj2: Group
    gltfLoader.load('2 узел .glb', (gltf) => {
      obj2 = gltf.scene;
      cylinder.add(obj2);
      obj2.translateX(10)
      obj2.position.setY(4)
      obj2.position.setX(8)
      obj2.position.setZ(-8)
    })
    let obj3: Group
    gltfLoader.load('3 узел .glb', (gltf) => {
      obj3 = gltf.scene;
      cylinder.add(obj3);
      obj3.translateX(20)
      obj3.position.setY(4)
      obj3.position.setX(-8)
      obj3.position.setZ(8)
    })
    let obj4: Group
    gltfLoader.load('4 узел .glb', (gltf) => {
      obj4 = gltf.scene;
      cylinder.add(obj4);
      obj4.translateX(30)
      obj4.position.setY(4)
      obj4.position.setX(-8)
      obj4.position.setZ(-8)
    })
    // console.log('cylinder', cylinder.children)


    // loop
    renderer.setAnimationLoop((time) => {
      
      time *= 0.001
      cylinder.rotation.y = time
      // time *= 0.001
      if (obj1) obj1.rotation.y = time
      if (obj2) obj2.rotation.y = time
      if (obj3) obj3.rotation.y = time
      if (obj4) obj4.rotation.y = time

      renderer.render(scene, camera)

    })

    // resize
    resize()
    document.body.onresize = resize

    // dom
    ref.current?.appendChild(renderer.domElement)

    return () => {

      scene.remove(cylinder)
      ref.current?.removeChild(renderer.domElement)

    }

  }, [])

  return (
    <>
      <div id="three" ref={ref}></div>
    </>
  )

}